var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center align-items-md-center"},[_c('div',{staticClass:"col-md-8"},[_c('v-card',{attrs:{"elevation":"8"}},[_c('v-card-title',{staticClass:"card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Aliquotas")])]),_c('div',{staticClass:"card-body"},[_c('b-table',{staticClass:"table table-head-custom table-vertical-center table-head-bg table-borderless",attrs:{"fields":['porcentagem', 'dias_uteis', 'acoes'],"items":_vm.lista_aliquotas,"per-page":_vm.perPage,"current-page":_vm.currentPage,"id":"filiais-table","show-empty":"","empty-text":"Nenhum registro encontrado!"},scopedSlots:_vm._u([{key:"cell(saldo)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatMoney")(data.item.saldo))+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('span',{staticClass:"label label-lg label-inline px-2 pb-2 pt-3",class:{
                'label-light-success': data.item.status === 1,
                'label-light-danger': data.item.status === 0,
                'label-light-warning': data.item.status === 2,
              }},[_vm._v(" "+_vm._s(data.item.status === 1 ? "Ativo" : "Inativo")+" ")])]}},{key:"cell(tipo)",fn:function(data){return [_c('span',{staticClass:"label label-lg label-inline px-2 pb-2 pt-3",class:{
                'label-light-success': data.item.tipo === 1,
                'label-light-info': data.item.tipo === 2,
              }},[_vm._v(" "+_vm._s(data.item.status === 1 ? "PF" : "PJ")+" ")])]}},{key:"head(acoes)",fn:function(){return [_c('span')]},proxy:true},{key:"cell(acoes)",fn:function({ item }){return [_c('div',{staticClass:"text-right w-100"},_vm._l((_vm.lista_btn),function(btn,index){return _c('v-tooltip',{key:index,attrs:{"top":"","color":btn.color},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial[btn.permisao]),expression:"lista_permissoes_filial[btn.permisao]"}],staticClass:"mr-1",attrs:{"fab":"","outlined":"","color":btn.color,"small":""},on:{"click":function($event){return _vm.acoes(item, btn.button)}}},'v-btn',attrs,false),on),[_c('i',{class:btn.icon})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(btn.title))])])}),1)]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.lista_aliquotas.length,"per-page":_vm.perPage,"aria-controls":"filiais-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }